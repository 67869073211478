import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Paper,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconPlus } from "@tabler/icons";
import { PageLoader } from "components/PageLoader/PageLoader";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState } from "react";
import { trpc } from "utils/trpc";
import avatar from "static/avatar.png";
import avatar2 from "static/avatar2.png";

import { IsLoggedUserWrap } from "wrappers/IsLoggedUserWrap";

import { useMainPageStyles } from "hooks/useMainPageStyles";
import Image from "next/image";
import { ScrollIcon } from "icons/ScrollIcon";
import { services } from "data/services";
import { reasons } from "data/reasons";
import { isOdd } from "utils/isOdd";
import { colors } from "utils/colors";
import { TelegramIcon } from "icons/TelegramIcon";
import { HandShakeIcon } from "icons/HandShakeIcon";

const telegramLink = "https://t.me/kododel";

function LocalButton({
  text,
  icon,
  href,
  target,
}: {
  text: string;
  icon: React.ReactNode;
  href?: string;
  target?: "_blank";
}) {
  const { classes } = useMainPageStyles();
  const body = (
    <>
      {text} {icon}
    </>
  );
  if (href) {
    return (
      <a className={classes.localButton} target={target} href={href}>
        {body}
      </a>
    );
  }
  return <button className={classes.localButton}>{body}</button>;
}

function FirstScreen() {
  const { classes } = useMainPageStyles();
  return (
    <div className={classes.firstScreen}>
      <SimpleGrid cols={1}>
        <Group className={classes.firstScreenHeader} position="apart">
          <Title className={classes.firstScreenHeadText}>кододел</Title>
          <Text
            component="a"
            href={telegramLink}
            className={classes.firstScreenHeadText}
          >
            телеграм
          </Text>
        </Group>
        <Group
          css={{
            flexDirection: "column",
          }}
          spacing={0}
        >
          <Group className={classes.firstScreenAvatar}>
            <Image src={avatar2} />
          </Group>

          <Text align="center" className={classes.firstScreenHeadline}>
            Приветствую тебя друг на моем сайте, предлагаю познакомимся
            поближе. 
          </Text>
          <LocalButton
            href={"#story"}
            text={"познакомиться"}
            icon={<ScrollIcon />}
          />
        </Group>
      </SimpleGrid>
    </div>
  );
}

function StoryScreen() {
  const { classes, cx } = useMainPageStyles();
  return (
    <Container id="story">
      <Text className={classes.storyText} align="center">
        Меня зовут Андрей Лобанов и я из сибирского города Кемерово. Созданием
        сайтов я начал заниматься с 2017 года на третьем курсе универа и по сей
        день усиливаю свою экспертизу, за это время я поучаствовал в создании
        100 сайтов, делал как обычные странички так и конструкторы сайтов и
        приложения для каршеринга. Даже довелось написать свой движок.
      </Text>
    </Container>
  );
}

function ServicesScreen() {
  const { classes, cx } = useMainPageStyles();
  return (
    <Container>
      <SimpleGrid cols={1}>
        <div className={classes.sectionHeader}>
          <Text className={classes.sectionHeadline}>
            И так теперь давай поговорим о том как я могу помочь тебе?
          </Text>
          <Text className={classes.sectionDescription}>
            Я могу осуществить любую твою задумку в виде сайта если в ней есть
            логика. 
          </Text>
        </div>
        <Divider className={classes.sectionDivider} />
        <SimpleGrid
          css={{
            alignItems: "start",
          }}
          breakpoints={[
            { minWidth: "md", cols: 3 },
            { maxWidth: "md", cols: 1 },
          ]}
          spacing={34}
        >
          {services.map((service) => (
            <div className={classes.sectionItem}>
              <div
                css={{
                  "& svg": {
                    display: "block",
                  },
                }}
              >
                {service.icon}
              </div>

              <Title className={classes.sectionItemTitle}>
                {service.title}
              </Title>
              <Text className={classes.sectionItemDescription}>
                {service.description}
              </Text>
            </div>
          ))}
        </SimpleGrid>
      </SimpleGrid>
    </Container>
  );
}

function ReasonsScreen() {
  const { classes, cx } = useMainPageStyles();
  return (
    <Container>
      <SimpleGrid cols={1}>
        <div className={classes.sectionHeader}>
          <Text className={classes.sectionHeadline}>Почему я?</Text>
        </div>
        <Divider className={classes.sectionDivider} />
        <SimpleGrid
          css={{
            alignItems: "start",
          }}
          breakpoints={[
            { minWidth: "md", cols: 3 },
            { maxWidth: "md", cols: 1 },
          ]}
          spacing={34}
        >
          {reasons.map((service) => (
            <div className={classes.sectionItem}>
              <div
                css={{
                  "& svg": {
                    display: "block",
                  },
                }}
              >
                {service.icon}
              </div>

              <Title className={classes.sectionItemTitle}>
                {service.title}
              </Title>
              <Text className={classes.sectionItemDescription}>
                {service.description}
              </Text>
            </div>
          ))}
        </SimpleGrid>
      </SimpleGrid>
    </Container>
  );
}

function ContactScreen() {
  const { classes } = useMainPageStyles();
  return (
    <Container>
      <SimpleGrid cols={1}>
        <div className={classes.contactShell}>
          <div className={classes.contactIcon}>
            <div className={classes.contactIconCenter}>
              <HandShakeIcon />
            </div>
          </div>
          <Text className={classes.contactHeadline}>
            Расскажите <br />о вашей задаче
          </Text>

          <LocalButton
            href={telegramLink}
            target="_blank"
            text={"рассказать"}
            icon={<TelegramIcon />}
          />
        </div>
      </SimpleGrid>
    </Container>
  );
}

export default function IndexPage() {
  // const createSite = trpc.useMutation('user.createSite', {
  //   onError: error => {
  //     showNotification({
  //       title: error.message,
  //       message: '',
  //       color: 'red',
  //     })
  //   },
  // })

  const router = useRouter();

  // const user = trpc.useQuery(
  //   [
  //     'user.getUserSites',
  //     {
  //       isArchive: false,
  //     },
  //   ],
  //   {
  //     refetchInterval: 500,
  //   }
  // )

  const { classes, cx } = useMainPageStyles();

  const countOfScreens = 5;

  return (
    <>
      <Head>
        <title>Кододел - Лобанов Андрей</title>
      </Head>

      {Array.from(Array(countOfScreens).keys()).map((index) => {
        function filterScreen() {
          switch (index) {
            case 0:
              return <FirstScreen />;
              break;
            case 1:
              return <StoryScreen />;
              break;
            case 2:
              return <ServicesScreen />;
              break;
            case 3:
              return <ReasonsScreen />;
              break;
            case 4:
              return <ContactScreen />;
              break;

            default:
              break;
          }
        }
        return (
          <section
            css={{
              zIndex: countOfScreens - index,
            }}
            className={cx(
              classes.commonSection,
              isOdd(index) ? classes.sectionLight : classes.sectionGrey,
              index !== 0 ? classes.paddingSection : undefined,
              index !== countOfScreens - 1 ? classes.radiusSection : undefined
            )}
          >
            {filterScreen()}
          </section>
        );
      })}
    </>
  );
}
