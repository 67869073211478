import { AlienIcon } from "icons/AlienIcon";
import { BicycleIcon } from "icons/BicycleIcon";
import { BookIcon } from "icons/BookIcon";
import { CalcIcon } from "icons/CalcIcon";
import { ChatIcon } from "icons/ChatIcon";
import { EnglishIcon } from "icons/EnglishIcon";
import { KeyboardIcon } from "icons/KeyboardIcon";
import { NetworkIcon } from "icons/NetworkIcon";
import { PencilIcon } from "icons/PencilIcon";
import { ScissorsIcon } from "icons/ScissorsIcon";
import { ThreeDIcon } from "icons/ThreeDIcon";

export const reasons: {
  icon: React.ReactNode;
  title: string;
  description: string;
}[] = [
  {
    icon: <ScissorsIcon />,
    title: "Я отсекаю все не нужное",
    description:
      "Я понимаю что бизнесу это снижение затрат и я умею не придумывать лишнюю работу, что сэкономит вам время и деньги",
  },
  {
    icon: <ChatIcon />,
    title: "Я умею общаться",
    description:
      "Я  расспрошу вас все нюансы вашей задачи и буду не стесняться забрасывать вас вопросами, ведь недопонимание удорожает создание программы",
  },
  {
    icon: <BicycleIcon />,
    title: "Не изобретаю велосипед",
    description: "Если есть что уже готовое решение то я предложу вам это",
  },
  {
    icon: <EnglishIcon />,
    title: "Хорошо понимаю английский",
    description:
      "Сейчас вся информация о программировании на английском языке и мне будет не трудно быстро найти информацию, что ускоряет работу и уменьшает затраты на разработку.",
  },
  {
    icon: <PencilIcon />,
    title: "Я самообучаюсь каждый год",
    description:
      "Слежу за последними технологиями которые удешевляют создание сайтов",
  },
  {
    icon: <KeyboardIcon />,
    title: "Плюс я печатаю вслепую на английском и русском",
    description: "За счет этого работа идет в разы быстрей",
  },
];
